@color-light-grey: #DCDCDC;

p a {
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}

.ant-modal-header {
  font-family: Oswald;
  text-transform: uppercase;
  border-bottom: none;
  padding-bottom: 0;
  .ant-modal-title {
    font-size: 1.6em;
  }
}

.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  &:hover {
    background-color: @color-light-grey;
  }
}
.ant-select-dropdown-menu-item:hover, .ant-select-dropdown-menu-item-active {
  &:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: @color-light-grey;
  }
}
@primary-color: #194173;@text-color: #0D1B2A;@font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;@font-size-base: 14px;